import React from "reactn"
import PropTypes from 'prop-types'
import { Box, Flex } from "@chakra-ui/layout"
import { LayoutMain } from "./layoutMain"
import { SearchBox } from "./search/searchBox"
import { Footer } from "./footer"
export function Layout({ children, location }) {
  return (
    <Box id="layoutEmpty">
      <Flex flexDirection={'column'} minHeight={'100vh'} >
        <SearchBox />
        <LayoutMain location={location}>
          <Box lex={1}>
            {children}
          </Box>
        </LayoutMain>
      </Flex>
      <Footer />
    </Box>

  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.object.isRequired
}

Layout.defaultProps = {
}
