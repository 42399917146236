
import React, { setGlobal, useState, useLayoutEffect, useGlobal, useEffect } from 'reactn'
import { connectSearchBox } from 'react-instantsearch-dom';
import { CloseIcon } from '@chakra-ui/icons'
import { useLocation } from "@reach/router"
import { useBreakpointValue } from "@chakra-ui/react"
import { navigate } from 'gatsby';
import SearchLogo from './searchLogo'
import './searchBox.css'
import {
    Box,
    Input,
    InputGroup,
    InputRightElement,
    InputLeftElement
} from "@chakra-ui/react";
import SlideDown from 'react-slidedown';
import Sticky from 'react-stickynode';
const CustomSearchBox = ({ currentRefinement, createURL, refine, isSearch }) => {
    const [timerId, setTimerId] = useState(false)
    const [rendered, setRendered] = useState(false)
    const [searchVisible] = useGlobal('searchVisible')
    const canHideSearch = useBreakpointValue({ base: false, sm: false, md: true, lg: true, xl: true })
    const [currentValue, setCurrentValue] = useState("")

    const location = useLocation();

    useEffect(() => {
        setCurrentValue(currentRefinement)
    }, [currentRefinement])
    useLayoutEffect(() => {
        setRendered(true)
    }, [])

    function closeSearch() {
        if (!isSearch || canHideSearch) {
            setGlobal({ searchVisible: false })
        } else {
            setGlobal({
                searchVisible: false,
                searchText: ""
            }
            )
            refine("")
        }
    }



    const onChangeDebounced = event => {
        const value = event.currentTarget.value;

        window.clearTimeout(timerId);
        setTimerId(window.setTimeout(() => {
            const url = createURL(value)
            if (location.pathname.replace(/\?.*/, "") != url.replace(/\?.*/, "")) {
                navigate(url)
            } else {
                refine(value);
            }

        }, 350))

        if (event.currentTarget.value.length >= 3) {
            setGlobal({ searchText: event.currentTarget.value })
        } else {
            setGlobal({ searchText: "" })
        }

        setCurrentValue(event.currentTarget.value)
    };
    return (
        <>
            {rendered &&
                <Box mt={0}>
                    <SlideDown className={'searchText-slidedown'} closed={(!rendered || !searchVisible)}>
                        <Sticky innerZ={2000} enabled={true} top={56} >
                            <Box backgroundColor={'white'} pt={1} pb={0}>
                                <Box id="searchBox" ml={0} mr={0} mb={0} height={50} backgroundColor={'white'} borderWidth={0}>
                                    <InputGroup borderWidth={0} >
                                        <InputLeftElement children={
                                            <Box w={6} h={6} mb={2} ml={2} mt={5} mr={5}>
                                                <SearchLogo isWhite={true} />
                                            </Box>
                                        } />
                                        <Input variant={'searchbox'} width={'800%'} value={currentValue}
                                            _placeholder={{ color: 'brandWhite.300' }}
                                            borderRadius={'none'}
                                            height={50}
                                            borderRightWidth={0}
                                            borderLeftWidth={0}
                                            // placeholder="Outline"
                                            onChange={onChangeDebounced}
                                            placeholder="What would you like to search for" />
                                        <InputRightElement mt={1} children={<CloseIcon color="gray.600" />} onClick={() => closeSearch()} />
                                    </InputGroup>
                                </Box>
                            </Box>
                        </Sticky>
                    </SlideDown>
                </Box>
            }
        </>
    );
};
export const SearchBox = connectSearchBox(CustomSearchBox);;



