import { Box, Center, Text } from "@chakra-ui/layout"
import React from "react"
import { Layout } from "../components/layoutEmpty"
import PageHeader from "../components/pageHeader"
export default function Success() {
  return (
    <>
      <PageHeader title={'CONTACT REQUEST'} />
      <Box
        textAlign={'center'}
        position={'fixed'}
        top={'50%'}
        left={'50%'}
        transform={'translate(-50%, -50%)'}
      >
        <Text fontSize='xl'>
          <p>
            Thank you. We will get back to you as quickly as possible.
          </p>
        </Text>
      </Box>
    </>
  )
}

Success.Layout = Layout

