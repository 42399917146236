import React from "reactn"
import { Heading, Text, Box, Container } from "@chakra-ui/react"
import { GatsbyImage } from "gatsby-plugin-image"

export default function PageHeader({ title, isStory, subtitle, text, titleImage }) {


  return (
    <Box id="pageHeader" mt={'10px'}  >
      {titleImage &&
        <Box marginBottom={5}>
          <GatsbyImage
            alt={title}
            title={title}
            image={titleImage.gatsbyImageData}
            draggable={false}
          />
        </Box>
      }
      {title &&

        <Heading as="h2" mt={'0px'} id="pageTitleHeader" align={'center'} variant={'pageHeading'} >
          {title}
        </Heading>

      }
      {subtitle && isStory != false &&

        <Heading as="h3" mt={'0px'} id="pageSubHeader" align={'center'} variant={'pageSubTitle'} >
          {subtitle}
        </Heading>

      }
      {text &&

        <Container maxW={'100%'} width={'100%'} id="pageHeaderText" align={'center'} variant={'pageHeading'}
          dangerouslySetInnerHTML={{ __html: `<div> ${text} </div>` }}>
        </Container>

      }
    </Box>
  )
}

